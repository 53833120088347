<template>
    <div class="home">
        <div class="home__arte-promocao">
            <a @click="() => router.push({ name: 'Premios' })">
                <img class="arte-g" :src="state.promocao.imagem" :alt="state.promocao.titulo">
            </a>
        </div>
        <div class="home__conteudo">
            <div class="bt bt--destaque">
                <a type="button" @click="() => router.push({ name: 'FormaPagto' })"> Participe </a>
            </div>
            <p>Aqui na Rede America Plus você interage com a nossa programação, assiste aos seus artistas preferidos e ainda concorre a prêmios todos os dias.</p>

            <div class="home__conteudo--item" v-for="depoimento in state.depoimentos" :key="depoimento.id">
                <span>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>communication, message, chat, text, talk</title><path d="M12,2.41c-6.73,0-12,3.46-12,7.88,0,3.59,3.53,6.63,8.69,7.58l.2,2.33A1.52,1.52,0,0,0,10,21.53a1.61,1.61,0,0,0,.44.06A1.5,1.5,0,0,0,11.59,21l2.36-3C19.8,17.45,24,14.22,24,10.29,24,5.87,18.73,2.41,12,2.41Zm1.36,13.7-.43,0-2.15,2.68-.24-2.7L9.75,16C5.26,15.39,2,13,2,10.29,2,7.1,6.58,4.41,12,4.41S22,7.1,22,10.29C22,13.16,18.2,15.71,13.36,16.11Z"/><rect x="7" y="9.41" width="2" height="2"/><rect x="11" y="9.41" width="2" height="2"/><rect x="15" y="9.41" width="2" height="2"/></svg>
                    Depoimento | <span>{{ depoimento.titulo }}</span>
                </span>
                    <iframe class="video-home" :src="'https://player.vimeo.com/video/' + depoimento.video" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                <p>Depoimento dos ganhadores do Rede America Prêmios</p>
                <!-- <a href="">#Depoimentos</a> -->
            </div>

            <p class="texto-menor">Certificado de Autorização SECAP/ME n° 04.012780/2021.</p>
        </div>
    </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'

export default {
  setup () {
    const user = JSON.parse(window.localStorage.getItem('user'))
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      depoimentos: {},
      promocao: {}
    })

    async function getDepoimentos () {
      try {
        state.isLoading = true
        const { data, errors } = await services.depoimentos.index()

        if (!errors) {
          state.depoimentos = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Depoimentos não encontrado')
        }

        if (errors.status === 401) {
          toast.error('Depoimentos Inválidos')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os Depoimentos!')
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getDepoimentos()

    async function getPromocao () {
      try {
        state.isLoading = true
        const { data, errors } = await services.promocao.index()

        if (!errors) {
          state.promocao = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Promoção não encontrada')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar a Promoção!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPromocao()

    return {
      router,
      state,
      user
    }
  }
}
</script>
